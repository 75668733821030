import React, { useState, useContext, useRef } from "react";

import { BsCart2 } from "react-icons/bs";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import { ShopContext } from "../context/shop-context";
import { Link, Switch, Route } from "react-router-dom";
import { HashLink } from "react-router-hash-link";


const Navbar = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const {cartItems} = useContext(ShopContext);


  // const menuOptions = [
  //   {
  //     text: "Home",
  //     icon: <HomeIcon />,
  //     href: "#",
  //   },
  //   {
  //     text: "Contact",
  //     icon: <PhoneRoundedIcon />,
  //     href: "#",
  //   },
  //   {
  //     text: "Cart",
  //     icon: <ShoppingCartRoundedIcon />,
  //     href: "/cart",
  //   },
  // ];
  return (
    <nav>
      <div className="navbar-links-container">
        <Link to="/">Strona główna</Link>
        <Link to="/">Quizy</Link>
        <HashLink smooth to="/#about">O autorze</HashLink>
        {/* <HashLink smooth to="/#contact">Kontakt</HashLink> */}
        <Link to="/cart">
          <button className="navbar-cart-container">
        <BsCart2 className="navbar-cart-icon" size={22}/>
        {cartItems.length>0 && (<span className="item-count">{cartItems.length}</span>) }
        </button>
        </Link>
        <Link to="/store"><button className="primary-button">Sklep</button></Link>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: '200px;' }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            <ListItem>
            <Link to="/">Strona główna</Link>
            </ListItem>
            <ListItem>
            {/* <Link to="/">Quizy</Link> */}
            </ListItem>
            <ListItem><Link to="/store">Sklep</Link></ListItem>
            <ListItem><Link to="/cart">Koszyk</Link></ListItem>
            {/* {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))} */}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  )
}

export default Navbar