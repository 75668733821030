import './App.css';
import Home from './components/Home';
import Footer from './components/Footer';
import Books from './components/Books';
import About from './components/About';
import Store from './components/Store';
import Navbar from './components/Navbar';
import BookAbout from './components/BookAbout';
import Terms from './components/Terms';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import axios from "axios";
import React, { Component, useState, useEffect, createContext, useContext } from 'react';
import { MyContext } from "./MyContext";
import Cart from './components/Cart';
import { ShopContextProvider } from './context/shop-context';
import * as https from 'https';

function App() {
  const [books, setBooks] = useState(null)
  const [cart, setCart] = useState([])
  const fetchBookData = () => {
    axios.get('https://angielskie-ebooki.pl:8000/books')
    .then(response => {
      return response.data
    })
    .then(data => {
      setBooks(data)
    })
  }

  useEffect(() => {
    fetchBookData()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const addToCart = (book) => {
      setCart(...(cart.push(book)))
  }

  return (
    <MyContext.Provider value={{books, setBooks}}>
      <ShopContextProvider>
      <Router>
        <div>
          <Navbar/>
        <Switch>
          <Route exact path="/">
            <Home/>
            { books ? <Books books = {books} /> : 'loading...'}
            <About/>
          </Route>
          <Route exact path="/store">
            <Store/>
          </Route>
          <Route exact path="/books/:productId">
            <BookAbout/>
          </Route>
          <Route exact path="/cart">
            <Cart/>
          </Route>
          <Route exact path="/terms">
            <Terms/>
          </Route>
        </Switch>
        <Footer/>
      </div>
      </Router>
      </ShopContextProvider>
  </MyContext.Provider>
  );
}

export default App;
