import React from "react";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-wrapper" id="contact">
      <div className="footer-section-one">
        <div className="footer-icons">
          <a href="https://www.facebook.com/profile.php?id=61555406641713"><FaFacebookF /></a>
          <a href="https://www.instagram.com/angielskieebooki/"><FaInstagram/></a>
        </div>
        <div className="footer-section-columns">
          <span>Kontakt:</span>
          <span>Wydawnictwo DIF</span>
          <span>ul. Wierzbowa 7</span>
          <span>86-031 Osielsko</span>
          <span>radoslaw.wieckowski@gazeta.pl</span>
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-row">
          <span><Link to="/store">Sklep</Link></span>
          <span>O autorze</span>
          <span><Link to="/terms">Regulamin sklepu</Link></span>
          {/* <span>Polityka prywatności</span> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;