import {useParams} from "react-router-dom"
import React, { Component, useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import BookCardStore from './BookCardStore';
import {BiChevronDown} from 'react-icons/bi';
import axios from "axios";
import BookCover from '../assets/ISBN 978-83-968474-7-8.jpg';
import StarRating from "./StarRating";
import { MyContext } from "../MyContext";
import { ShopContext } from "../context/shop-context";

function BookAbout() {
  const { books } = useContext(MyContext);

    const {productId} = useParams()
    const thisProduct = books.find(prod => {return prod.id === +productId})
    const quantity = 0;

  const {addToCart} = useContext(ShopContext);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    return (
      <div className='book-about'>
              <Link to='/store'><div className="book-about-return">&lt; Wróć</div></Link>
               <div className="book-about-section">
                 <div className="book-about-section-top">
                 <div className="book-about-image"><img src={require(`../assets/${thisProduct.isbn}.jpg`)} alt=""/></div>
                 <div className='book-description'>
                   <div className='book-about-title'>{thisProduct.title}</div>
                   <div className="book-about-tags">
                   {/* <div className='book-about-tag'>epub</div>*/}
                   {/* <div className='book-about-tag'>pdf</div> */}
                   {["English Sentences Quiz", "English Prepositions Test", "English Articles Test"].includes(thisProduct.title) ? <div className='book-about-tag'>epub</div> : <div className='book-about-tag'>pdf</div>}
                   {/* <div className='book-about-tag'>epub</div> */}
                   </div>
                   <div className="book-about-section-top-price">{thisProduct.price} zł</div>
                   <div className="book-about-additional-info">
                   <div className="book-about-section-top-pages">
                     <b>Liczba stron</b> {thisProduct.pages}
                   </div>
                   <div className="book-about-section-top-size">
                     {/* <b>Rozmiar</b> 1,2 MB */}
                   </div>
                   </div>
                   <StarRating/>
                   {quantity === 0 ? (<button className="primary-button-add-to-cart" onClick={()=> addToCart(thisProduct)}>Do koszyka</button>) : null}
               </div>
                 </div>
                 <div className="book-about-section-bottom">
                   <div className="book-about-section-bottom-headers">
                     <div className="book-about-section-bottom-header">OPIS</div>
                     <div className="book-about-section-bottom-header">OPINIE(0)</div>
                   </div>
                   <div className="book-about-section-bottom-description">
                    <pre className="book-about-section-bottom-description-item"  >{thisProduct.description}</pre>
                   </div>
                 </div>
               </div>
          
             </div>
    )
}

export default BookAbout


// import React, { Component, useState, useEffect } from "react";
// import {Link} from "react-router-dom";
// import BookCover from '../assets/Angielski na co dzień w ciekawych dialogach.jpg';
// import Books from "./Books";
// import StarRating from "./StarRating";
// import axios from "axios";
// import {useParams} from "react-router-dom"


// const BookAbout = (props) => {
//   const [books, setBooks] = useState([])

//   const fetchBookData = () => {
//     axios.get('http://127.0.0.1:8000/books')
//     .then(response => {
//       return response.data
//     })
//     .then(data => {
//       setBooks(data)
//     })
//   }

//   useEffect(() => {
//     fetchBookData()
//   }, [])

//   const {productId} = useParams()
//   const thisProduct = books.find(prod => prod.id === productId)
//   console.log(thisProduct)

//     return (
//       <div className='book-about'>
//         <div className="book-about-return">&lt; Wróć</div>
//         <div className="book-about-section">
//           <div className="book-about-section-top">
//           <div className="book-about-image"><img src={BookCover} alt=""/></div>
//           <div className='book-description'>
//             <div className='book-about-title'>{thisProduct.title}</div>
//             <div className="book-about-tags">
//             <div className='book-about-tag'>epub</div>
//             <div className='book-about-tag'>mobi</div>
//             <div className='book-about-tag'>pdf</div>
//             </div>
//             <div className="book-about-section-top-price">60 zł</div>
//             <div className="book-about-additional-info">
//             <div className="book-about-section-top-pages">
//               <b>Liczba stron</b> 100
//             </div>
//             <div className="book-about-section-top-size">
//               <b>Rozmiar</b> 1,2 MB
//             </div>

//             </div>
//             <StarRating/>
//             <button className="primary-button-add-to-cart">Do koszyka</button>
//         </div>
//           </div>
//           <div className="book-about-section-bottom">
//             <div className="book-about-section-bottom-headers">
//               <div className="book-about-section-bottom-header">OPIS</div>
//               <div className="book-about-section-bottom-header">OPINIE(0)</div>
//             </div>
//             <div className="book-about-section-bottom-description">
//             • Ebook, którego zawartość nie sposób porównać do innych publikacji razem wziętych i dostępnych na
// rynku
// • Najobszerniejsza w Polsce prezentacja angielskiego bezokolicznika
// • Formy bezokolicznikowe i rzeczownika odsłownego najczęściej stosowane w języku codziennym, pisanym
// jak i mówionym
// • Zestawienie czasowników obok siebie w ćwiczeniach kontrastujących
// • Uwydatnienie najdrobniejszych różnic:
// When did she start losing weight?
// Kiedy zaczęła tracić wagę? (działanie zamierzone)
// When did she start to lose weight?
// Kiedy zaczęła tracić wagę? (działanie niezamierzone - powodem utraty wagi mogła być choroba)
// • Najtrudniejsze zagadnienia wyjaśnione na prostych przykładach
// • Testy systematyzujące wcześniej przerobiony materiał
// • Uniwersalny i nieodzowny suplement do innych podręczników na wszystkich poziomach zaawansowania
//             </div>
//           </div>
//         </div>
    
//       </div>
//     )
//   }
  
//   export default BookAbout