import React, {createContext, useContext, useState} from "react";

export const ShopContext = createContext(null);

const getDefaultCart = () => {
    let cart = {}
    for (let i=1; i<100; i++){
        cart[i] =0;
    }
    return cart;
}

export const ShopContextProvider = (props) =>{
    const [cartItems, setCartItems] = useState([])

    const addToCart = (product) => {
        setCartItems((prev)=>([...prev, product]))
    };

    const removeFromCart = (product) => {
        setCartItems((prev)=>(prev.filter((item)=> item.id !== product.id)))
    };
    const contextValue = {cartItems, addToCart, removeFromCart};
    return <ShopContext.Provider value={contextValue}>{props.children}</ShopContext.Provider>
}