import React from 'react';
import AboutImage from '../assets/about.png'

const About = () => {
  return (
    <div className='about' id="about">
        <div className='about-image'><img src={AboutImage} alt=""/></div>
        <div className='about-info'>
        <div className='about-header'>O autorze</div>
        <div className='about-text'>Radosław Więckowski - filolog angielski ze specjalizacją w zakresie literatury kanadyjskiej (UMK, Toruń); tytuł MBA (Dominican University of Chicago). Pracuje w branży IT na stanowisku Contract Specialist.</div>
        </div>
      </div>
  )
}

export default About