import React, { useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import {BiChevronDown} from 'react-icons/bi';
import { MyContext } from "../MyContext";
import StarRating from "./StarRating";
import ReactStars from "react-rating-stars-component";


const Products = () => {
  const { books } = useContext(MyContext);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const products = books.map(product => {
    return (
      <div key={product.id} className='product-card'>
        <Link to={`/books/${product.id}`} style={{textDecoration: 'none'}} className='product-card-content'>
        <div className="book-card-img"><img src={require(`../assets/${product.isbn}.jpg`)} className='book-card-image'/></div>
        <div className="book-card-info">
            <div className="book-card-info-title">{product.title}</div>
            <div className="book-card-info-type">E-book</div>
            {/* <ReactStars
          count={5}
          size={24}
          activeColor="#ffd700"
          value={5}
          /> */}
            <div className="book-card-info-price">{product.price} zł</div>
            <div className='book-card-button'><button className="primary-button-buy">Kup</button></div>
        </div>
        </Link>
      </div>
    );
  });

  return (
    <>
      <div className='store'>
      <div className='store-side-menu'>
        <div className='store-header'>Kategorie</div>
      <p>Filtruj <BiChevronDown/> </p>
       <p>Sortuj według <BiChevronDown/></p>
       </div>
       <div className='store-products'>
       <div className='store-header'>
             Książki
         </div>
         <div className='store-row'>
         {products}
         </div>
       </div>
     </div>
    </>
  );
};

export default Products;
