import React, {useContext} from "react";
import { MyContext } from "../MyContext";
import { ShopContext } from "../context/shop-context";
import ClearIcon from '@mui/icons-material/Clear';
import axios from "axios";
import {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


const Cart = () =>{
    const {cartItems, removeFromCart} = useContext(ShopContext);
    const [email, setEmail] = useState('');
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const changeEmail = event => {
        setEmail(event.target.value);
      };
    let total = 0;
    const sendEmail = (event) => {
        event.preventDefault();
        let booksToBuy = []
        for (let i = 0; i<cartItems.length; i++){
            booksToBuy.push({"title": cartItems[i].title,"ISBN": cartItems[i].isbn, "price": cartItems[i].price })
        }
        console.log({
            "email": email,
            "books_to_buy": booksToBuy
            })
        axios.post("https://angielskie-ebooki.pl:8000/order/",{
        "email": email,
        "books_to_buy": booksToBuy
        })
          .then((response) => {
            console.log(response);
          }, (error) => {
            console.log(error);
          });
    }
    const items = cartItems.map(cartItem => {
        total+=parseInt(cartItem.price);
    return(
        <div key={cartItem.id} className="cart-item">
            <div><img src={require(`../assets/${cartItem.isbn}.jpg`)} className="cart-item-img"/></div>
            <div className="cart-item-title">{cartItem.title}</div>
            <div className="cart-item-price">{cartItem.price} zł</div>
            <button onClick={()=> removeFromCart(cartItem)} className="remove-item"><ClearIcon/></button>
        </div>

    )})

    return(
        <div className="cart">

        <div className="cart-header">Twój koszyk</div>
        <div className="cart-all">
        <div className="cart-container">
            {items}
        </div>
        <div className="cart-checkout">
            <div className="cart-checkout-total">Do zapłaty:</div>
            <div className="cart-checkout-price">{total} zł</div>
            <form onSubmit={sendEmail}>
            <label for="fname" style={{fontSize: "12.80px"}}>e-mail:</label><br/>
            <input onChange={changeEmail} type="email" id="fmail" name="fmail" required/><br/><br/>
            <input type="checkbox" id="scales" name="scales" required />
            <label for="scales" style={{fontSize: "14px"}}>Proszę o dostawę treści cyfrowych po dokonaniu płatności. Wiem, że w
            ten sposób tracę prawo do odstąpienia od umowy.</label><br/>
            <button type="submit" value="Zamawiam" className="cart-button" onClick={handleOpen}>Zamawiam</button>
            </form>
        </div>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Dziękujemy!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Twoje zamówienie zostało złożone. Postępuj zgodnie z instrukcją w mailu.
          </Typography>
        </Box>
      </Modal>

        </div>
        </div>
    )
}

export default Cart;